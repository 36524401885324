import React from 'react'
import { Image } from '@mantine/core'
import logo from '../../assets/images/malug.png'

import { FaInstagram, FaLinkedin, FaEnvelope, FaBlogger  } from 'react-icons/fa'
import { Divider } from '@mantine/core'

function index() {
   return (
      <>
      <div className='bg-primary px-5'>
         <div className='max-w-6xl mx-auto'>
            <div className=' flex justify-between items-center py-10 flex flex-wrap gap-3'>
               <div className='flex gap-8'>
                  {/* <FaTwitter className='text-3xl text-white' />
                  <FaFacebook className='text-3xl text-white' /> */}
                  <a href="https://www.instagram.com/malugbrasil/" target="_blank" rel="noreferrer">
                     <FaInstagram className='text-3xl text-white' />
                  </a>
                  <a href="https://www.linkedin.com/company/malugbr/about/" target="_blank" rel="noreferrer">
                     <FaLinkedin className='text-3xl text-white' />
                  </a>
                  <a href="http://blog.malug.com.br/"  target="_blank" rel="noreferrer">
                     <FaBlogger  className='text-3xl text-white' />
                  </a>
                  <a href="mailto:relacionamento@malug.com.br" >
                     <FaEnvelope  className='text-3xl text-white' />
                  </a>
               </div>
               <div className='flex'>
                  <a href='https://api.whatsapp.com/send?phone=5511989329329' 
                     className='text-white'
                     target="_blank"
                  >
                     +55 (11) 98932-9329
                  </a>
               </div> 
            </div>

            <Divider color="white" size={0.1} />

            <div className='sm:flex hidden justify-between pb-10'>
               <div className='mt-10 flex flex-col'>
                  <Image src={logo} w={130} />
               </div>
               <div className='mt-10 flex flex-col gap-3'>
                  <h4 className='text-white font-bold pb-5'>INÍCIO</h4>
                  <a className='text-white' href="/">Dados Pessoais</a>
                  <a className='text-white' href="/">Reservas</a>
                  <a className='text-white' href="/">Pagamentos</a>
               </div>
               <div className='mt-10 flex flex-col gap-3'>
                  <h4 className='text-white font-bold pb-5'>SOBRE NÓS</h4>
                  <a className='text-white' href="/termos-e-condicoes">Termos e condições</a>
                  <a className='text-white' href="/politica-de-privacidade">Política de privacidade</a>
                  <a className='text-white' href="/trabalhe-conosco">Trabalhe na Malug</a>
                  <a className='text-white' target="_blank" href="https://expansaomalug.pro/">Seja um licenciado</a>
               </div>
               <div className='mt-10 flex flex-col gap-3'>
                  <h4 className='text-white font-bold pb-5'>SUPORTE</h4>
                  <h4 className='text-white'>Seguro mala</h4>
                  <a className='text-white' href="/">Como funciona</a>
               </div>
            </div>

            <div className='flex flex-col items-center pb-10  sm:hidden block'>
              
               <div className='mt-10 flex flex-col gap-3 items-center'>
                  <h4 className='text-white font-bold pb-5'>INÍCIO</h4>
                  <a className='text-white' href="/">Dados Pessoais</a>
                  <a className='text-white' href="/">Reservas</a>
                  <a className='text-white' href="/">Pagamentos</a>
               </div>
               <div className='mt-10 flex flex-col gap-3 items-center'>
                  <h4 className='text-white font-bold pb-5'>SOBRE NÓS</h4>
                  <a className='text-white' href="/termos-e-condicoes">Termos e condições</a>
                  <a className='text-white' href="/politica-de-privacidade">Política de privacidade</a>
                  <a className='text-white' href="/trabalhe-conosco">Trabalhe na Malug</a>
               </div>
               <div className='mt-10 flex flex-col gap-3 items-center'>
                  <h4 className='text-white font-bold pb-5'>SUPORTE</h4>
                  <h4 className='text-white'>Seguro mala</h4>
                  <a className='text-white' href="/">Como funciona</a>
                 
               </div>
               <div className='mt-10 flex flex-col'>
                  <Image src={logo} w={130} />
               </div>
            </div>
         </div>
      </div>
      <div className='flex  justify-center bg-white py-1'>
         <p className="flex justify-self-center self-center text-sm font-medium ">®Malug 2024 - Todos os direitos reservados.</p>
      </div>
      </>
   )
}

export default index