import React from 'react'

import Footer from '../../components/Footer'
import Header from '../../components/Header'


export default function PrivacyPolicy() {
    return (
        <>
            <Header title={'Política de privacidade'} />
            <div className={`w-full pb-10 p-5 sm:pt-5  mx-auto max-w-6xl`}>
                <strong>Termos e condições - MALUG Brasil</strong> <br />

                <p className='indent-8 text-justify'>O presente contrato tem por objeto a prestação de serviços pela Malug Brasil ao Contratante.</p><br />

                <p className='font-bold'>Cláusula 1ª.</p>

                <p className='indent-8 text-justify'>
                    A Malug Brasil compromete- se a disponibilizar para locação malas em perfeito estado de conservação, prontas, higienizadas e habilitadas para utilização na data solicitada.
                </p>
                
                <br />
                
                <p className='font-bold'>Cláusula 2ª.</p>
                <p className='indent-8 text-justify'>
                    O Locatário está ciente que as malas alugadas se trata de bens já utilizados por outros locatários, por isso poderá haver pequenos riscos e/ou arranhões e/ou marcas de uso, porém, sem comprometer a sua real necessidade de utilização.
                </p>
                
                <br />

                <p className='font-bold'>Cláusula 3ª.</p>
                <p className='indent-8 text-justify'>
                    O contratante deverá se certificar, no ato do recebimento da mala(s) as condições do estado de conservação da(s) mesma(s), podendo recusá-la(s) e solicitar sua substituição caso não esteja de acordo com o produto escolhido e/ou as condições negociadas. Todas as malas da Malug Brasil serão entregues ao locatário VAZIAS, sem qualquer objeto dentro, sendo que, todo e qualquer objeto, de qualquer natureza, a ser encontrado dentro da(s) mala(s), é de INTEIRA RESPONSABILIDADE DO LOCATÁRIO. Assim que a mala for recebida pelo cliente, a responsabilidade de sua guarda e manutenção passa a ser do mesmo (locatário), devendo a mala ser entregue nas mesmas condições do ato do recebimento.
                </p>
                
                <br />

                <p className='font-bold'>Cláusula 4ª.</p>
                <p className='indent-8 text-justify'>
                    A entrega da(s) mala(s) será feita mediante o pagamento antecipado da locação no ato da aceitação do contrato.
                </p>
                
                <br />

                <p className='font-bold'>Cláusula 5ª.</p>
                <p className='indent-8 text-justify'>
                    O cliente irá receber as malas em seu domicílio, no endereço cadastrado na hora da contratação do aluguel.
                </p>
                
                <br />

                <p className='indent-8 font-bold'>5.1</p>
                <p className='indent-16 text-justify'>
                    O frete deverá seguir as diretrizes e períodos/horários conforme listado abaixo: 
                </p>
                
                <br />

                <p className='indent-16 font-bold'>Período de entrega e retirada: </p>
                <p className='indent-16 text-justify'>
                    O frete deverá seguir as diretrizes e períodos/horários conforme listado abaixo: 
                </p>
                <p className='indent-16 text-justify'>        
                    • Segunda à Sexta – Feira disponível entrega das 09:00h às 17:00h. <br />
                </p>
                <p className='indent-16 text-justify'>        
                    • Sábado das 09:00h às 14:00h <br />
                </p>
                <p className='indent-16 text-justify'>        
                    • Domingo e Feriados: Não alugamos, nem agendamos entrega ou devolução nesses dias. 
                </p>
                
                <br />

                <p className='font-bold'>Cláusula 6ª.</p>
                <p className='indent-8 text-justify'>
                    O cliente se compromete a conferir a mala no momento de desembarque, sendo que, constatado qualquer dano ou avaria no produto, deverá procurar o representante da empresa responsável pelo transporte da bagagem e preencher o formulário de Registro de Irregularidade de Bagagem (RIB) ou outro documento emitido pela empresa responsável pelo extravio. Importante lembrar que este documento não isenta o cliente de efetuar o pagamento integral da mala, que poderá ser ressarcido pela companhia aérea. 
                </p>
                
                <br />

                <p className='font-bold'>Cláusula 7ª.</p>
                <p className='indent-8 text-justify'>
                    O atraso da devolução da mala por período superior a 36 horas caracteriza automaticamente apropriação indevida. Fica o CONTRATANTE ciente de que a diária será cobrada em valor cheio, sem desconto, para os dias excedidos, e o cliente poderá ser responsabilizado conforme o art. 168 do código penal. 
                </p>
                
                <br />

                <p className='font-bold'>Cláusula 8ª.</p>
                <p className='indent-8 text-justify'>
                Constatado, após a devolução no ato da conferência da mala, qualquer dano ou avaria ou excesso de sujeira, será cobrado do locatário o valor integral do conserto e/ou limpeza do produto. No caso de impossibilidade de recuperação da mala, o cliente se compromete a indenizar a Malug Brasil no valor integral da mala. 
                </p>
                
                <br />

                <p className='font-bold'>Cláusula 9ª.</p>
                <p className='indent-8 text-justify'>
                    A duração do contrato terá início na data de aceite do tempo e pagamento da locação da(s) mala(s), e se encerra até que todas as obrigações tenham sido cumpridas e/ou devolução das malas assim como, o pagamento dos débitos relacionados. 
                </p>
                
                <br />

                <p className='indent-8 font-bold'>9.1</p>
                <p className='indent-16 text-justify'>
                    Havendo a prorrogação no prazo de entrega das malas, a Malug Brasil deverá ser comunicada e formalizada por meios digitais cabíveis com o máximo 36 horas de antecedência e deverá permanecer em vigor todos os termos previstos neste contrato. Vale lembrar que, o cliente estará sujeito a cobrança de novos valores de diárias e fretes conforme for alterada a demanda. 
                </p>
                
                <br />

                <p className='font-bold'>Cláusula 10ª.</p>
                <p className='indent-8 text-justify'>
                    Em caso de desistência da locação, sendo essa solicitação dentro de 48 horas anteriores a retirada da mala, será ressarcido o valor integral pago até o momento. 
                </p>
                
                <br />

                <p className='indent-8 font-bold'>10.1</p>
                <p className='indent-16 text-justify'>
                Se a desistência acontecer após o período de 48 horas antecedentes a data da locação, a Viaje & Pronto! Aluguel de malas poderá reter 30% do valor já pago referente as taxas administrativas e contratuais.
                </p>
                
                <br />

                <p className='indent-8 font-bold'>10.2</p>
                <p className='indent-16 text-justify'>
                    Se a desistência ocorrer por parte da Malug Brasil por rescisão imotivada, a mesma deverá devolver a quantia total paga até o momento pelo cliente. 
                </p>
                
                <br />

                <p className='indent-8 font-bold'>Parágrafo Único:</p>
                <p className='indent-16 text-justify'>
                Se a locação for efetivada, independente do tempo, ou seja, o objeto for retirado pelo cliente, não fará jus a qualquer quantia a ser devolvida de valores pagos, mesmo que os itens sejam entregues antes do prazo. 
                </p>
                
                <br />

                <p className='font-bold'>Cláusula 11ª.</p>
                <p className='indent-8 text-justify'>
                    O contratante reconhece que a Malug Brasil opera sob o CNPJ da Trinc Tecnologies, uma vez que a Malug Brasil é um produto desenvolvido e mantido pela Trinc Tecnologies. A Trinc Tecnologies é uma empresa especializada em soluções tecnológicas e foi responsável pela criação, desenvolvimento e implementação das funcionalidades da Malug, garantindo sua operação e suporte sob a sua estrutura empresarial. Assim, todas as responsabilidades contratuais e comerciais vinculadas ao produto Malug Brasil são geridas diretamente pela Trinc Tecnologies, que exerce todas as obrigações pertinentes à operação, manutenção e aprimoramento do referido produto. 
                </p>
                
                <br />

                <p className='font-bold'>Cláusula 12ª.</p>
                <p className='indent-8 text-justify'>
                Declaro para os fins de direito, que conforme disposto no presente contrato, estar ciente de todas as disposições contratuais e que, o objeto do contrato, após minha conferência, foi entregue em perfeito estado, de acordo com o firmado entre as partes. 
                </p>
                
                <br />

                <p className='font-bold'>Cláusula 13ª.</p>
                <p className='indent-8 text-justify'>
                O Contrato será regido pela Lei nº 8.078, de 11 de setembro de 1990 - Código de Defesa do Consumidor e Lei nº 10.406, de 10 de janeiro de 2002 - Código Civil, Decreto nº 7.962, de 15 de março de 2013, outras leis e regulamentos sobre comércio eletrônico, bem como pelas Condições Gerais estipuladas e aceitas pelas Partes neste instrumento. 
                </p>
                
                <br />

                <p className='font-bold'>Cláusula 14ª.</p>
                <p className='indent-8 text-justify'>
                    Fica eleito o foro da Cidade de São Luis do estado do Maranhão, para dirimir quaisquer litígios ou esclarecer dúvidas referentes ao presente instrumento, não podendo ser escolhido qualquer outro por mais privilegiado que for. 
                </p>
                
                <br />

                <p className='font-bold'>Cláusula 14ª.</p>
                <p className='indent-8 text-justify'>
                    Fica eleito o foro da Cidade de São Luis do estado do Maranhão, para dirimir quaisquer litígios ou esclarecer dúvidas referentes ao presente instrumento, não podendo ser escolhido qualquer outro por mais privilegiado que for. 
                </p>
                
                <br />
            </div>
            <Footer />
        </>
    )
}