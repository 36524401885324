import React, { useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { MantineProvider } from '@mantine/core';
import { useSelector } from 'react-redux'

import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";

import './index.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import 'react-credit-cards/es/styles-compiled.css';
import 'react-toastify/dist/ReactToastify.css';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';

import { ToastContainer } from 'react-toastify'

//Pages
import Home from './pages/home';
import Shopping from './pages/shopping'
import Auth from './pages/auth';
import Checkout from './pages/checkout';
import Endereco from './pages/address';
import PaymentPix from './pages/paymentPix';
import Account from './pages/account';
import PoliticaDePrivacidade from './pages/privacyPolicy';
import TermosECondicoes from './pages/termsAndConditions';
import TrabalheConosco from './pages/workWithUs'
import OrdemDeServico from './pages/booking'

import { connect } from "react-redux";
import { setStates } from './redux/actions'
import { states } from './utils';

function App(props) {
   

   const simulate = useSelector(state => state.Simulate.simulate)

   const PrivateRoute = ({ children, redirectTo }) => {
      const isAuthenticated = localStorage.getItem("token_cliente") !== null;
      return isAuthenticated ? children : <Navigate to={redirectTo} />;
   };

   const PrivateRouteCheckout = ({ children, redirectTo }) => {
      const isAuthenticated = localStorage.getItem("token_cliente") !== null;
      return isAuthenticated && simulate !== null ? children : <Navigate to={redirectTo} />;
   };

   useEffect(() => {
      props.setStates(states)  
   }, [props])

   useEffect(() => {
      CookieConsent.run({
         categories: {
            necessary: {
               enabled: true,  // this category is enabled by default
               readOnly: true  // this category cannot be disabled
            },
            analytics: {}
         },
         language: {
            default: 'pt',
            translations: {
               pt: {
                  consentModal: {
                     title: 'Nós usamos cookies',
                     description: 'Utilizamos cookies para que você tenha a melhor experiência em nosso site. Para saber mais acesse nossa página de Política de Privacidade',
                     acceptAllBtn: 'Aceitar todos',
                     acceptNecessaryBtn: 'Rejeitar todos',
                     
                  }
               }
            }
         },
         guiOptions: {
            consentModal: {
                layout: 'box',
                position: 'bottom left',
                flipButtons: false,
                equalWeightButtons: true
            }
        }
      });
   }, []);


   return (
      <MantineProvider withGlobalStyles withNormalizeCSS theme={{}}>  
         <ToastContainer />
            <BrowserRouter>
               <Routes>
                  <Route
                     path="/conta/:route"
                     element={
                        <PrivateRoute redirectTo="/">
                           <Account />
                        </PrivateRoute>
                     }
                  />
                  <Route
                     path="/checkout"
                     element={
                        <PrivateRouteCheckout redirectTo="/">
                           <Checkout />
                        </PrivateRouteCheckout>
                     }
                  />
                  <Route
                     path="/endereco"
                     element={
                        <PrivateRouteCheckout redirectTo="/">
                           <Endereco />
                        </PrivateRouteCheckout>
                     }
                  />
                  <Route
                     path="/qrcode"
                     element={
                        <PrivateRouteCheckout redirectTo="/">
                           <PaymentPix />
                        </PrivateRouteCheckout>
                     }
                  />
                  <Route
                     path="/ordem-de-servico/:id"
                     element={
                        <PrivateRoute redirectTo="/">
                           <OrdemDeServico />
                        </PrivateRoute>
                     }
                  />
                  <Route path="/" element={<Home />} />
                  <Route path="/shopping" element={<Shopping />} />
                  <Route path="/identificacao" element={<Auth />} />
                  <Route path="/politica-de-privacidade" element={<PoliticaDePrivacidade />} />
                  <Route path="/termos-e-condicoes" element={<TermosECondicoes />} />
                  <Route path="/trabalhe-conosco" element={<TrabalheConosco />} />
               </Routes>
            </BrowserRouter>
      </MantineProvider>
   );
}

const mapStateToProps = (state) => {
	const { loading, error } = state.Auth;
	return { loading, error };
 };
 
export default connect(mapStateToProps, { setStates })(App);
